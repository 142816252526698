



































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import VWidget from '@/components/VWidget.vue';
import PageRequest from '@/store/entities/page-request';
import Util from '@/lib/util';

@Component({
    components: { VWidget },
})

export default class Widgets extends AbpBase {
    @Prop({ type: PageRequest, default: () => { return new PageRequest() } }) pagerequest: PageRequest;

    get widgets() {
        let values = this.$store.state.dashboard.widgets;
        let wids = [
            { value: values[0], name: 'Total Solicitudes', icon: 'library_books', desc: 'Solicitudes Generadas' },
            { value: '', name: 'spacer' },
        ];

        if (!this.isCommercial)
            wids.push(...[
                { value: values[1], name: 'Promedio de fecha', icon: 'pending', desc: 'Tiempo de Atencion' },
                { value: values[2], name: 'Promedio de Horas', icon: 'touch_app', desc: 'Tiempo de Atencion' },
            ]);

        return wids;
    }

    get isCommercial() {
        return Util.abp.session.isCommercial();
    }

    get loading() {
        return this.$store.state.dashboard.loading;
    }

    formatNumber(amount, decimals = 0) {
        return Util.formatNumber(amount, decimals);
    }


    async loadData() {

        await this.$store.dispatch({
            type: 'dashboard/getWidgetValues',
            data: this.pagerequest
        });
    }

    mounted() {
        this.loadData();
    }
}
