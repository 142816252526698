



























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import CusDatePicker from '@/components/controls/cus-date-picker.vue';
import VWidget from '@/components/VWidget.vue';
import PageRequest from '@/store/entities/page-request';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import FormBase from '@/components/helper/form-base.vue';
import Applications from './applications.vue';
import Atentions from './atentions.vue';
import Widgets from './widgets.vue';

class PageChartRequest extends PageRequest {
    customer: number = null;
    vertical: number = 0;
    date_from: string = Util.abp.clock.today().substr(0, 4) + '-01-01';
    date_to: string = Util.abp.clock.today();
    state_from: number = Util.abp.list.get('E_APPLY','NU').id;
    state_to: number = Util.abp.list.get('E_APPLY', 'AP').id;
}

@Component({
    components: { CusDatePicker, VWidget, FormBase, Applications, Atentions, Widgets },
})

export default class HomeView extends ListBase {
    pagerequest: PageChartRequest = new PageChartRequest();
    filterForm: boolean = true;

    filterItems = [
        this.gItem({ type: 'radio', prop: 'vertical', label: 'Vertical', size: 12, showAll: true, hideDetails: true, generalType: 'T_VERTICAL', row: true, onchange: this.loadData }),
        this.gItem({ type: 'object', prop: 'customer', label: 'Buscar por Empresa', itemText: 'description', autocomplete: true, size: 3, returnObject: false, hideDetails: true, onchange: this.loadData }),
        this.gItem({ type: 'date', prop: 'date_from', label: 'Fecha Desde', size: 2, hideDetails: true, onchange: this.loadData }),
        this.gItem({ type: 'date', prop: 'date_to', label: 'Hasta', size: 1, hideDetails: true, onchange: this.loadData }),
        this.gItem({ type: 'spacer', size: (this.isCommercial ? 6 : 2) }),
        this.gItem({ type: 'object', prop: 'state_from', label: 'Rango de Estados - Desde', generalType: 'E_APPLY', size: 2, returnObject: false, hideDetails: true, onchange: this.loadWidget, visible: !this.isCommercial }),
        this.gItem({ type: 'object', prop: 'state_to', label: 'Hasta', generalType: 'E_APPLY', size: 2, returnObject: false, hideDetails: true, onchange: this.loadWidget, visible: !this.isCommercial }),
    ]

    get loading() {
        return this.$store.state.dashboard.loading;
    }

    get isCommercial() {
        return Util.abp.session.isCommercial();
    }

    async loadData() {
        if (this.$refs.app != undefined)
            (this.$refs.app as any).loadData();

        this.loadWidget();
    }

    async loadWidget() {
        if (this.$refs.wid != undefined)
            (this.$refs.wid as any).loadData();
        
        if (this.$refs.att != undefined)
            (this.$refs.att as any).loadData();            
    }


    async mounted() {
    }

    async created() {

    }
}
