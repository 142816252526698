
















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request';
import Util from '@/lib/util';

@Component({
    components: {  },
})

export default class Attentions extends AbpBase {
    @Prop({ type: PageRequest, default: () => { return new PageRequest() } }) pagerequest: PageRequest;
    
    columns = [
        { value: 'code', text: 'Nro', sortable: false },
        { value: 'issue_date', text: 'Fecha de Solicitud', align: 'center', sortable: false },
        { value: 'days', text: '#', align: 'center', sortable: false },
        //{ value: 'days_sum', text: 'Sum', align: 'center', sortable: false },
        //{ value: 'days_prom', text: 'Prom', align: 'center', sortable: false },
        { value: 'hours', text: '#', align: 'center', sortable: false },
        //{ value: 'hours_sum', text: 'Sum', align: 'center', sortable: false },
        //{ value: 'hours_prom', text: 'Prom', align: 'center', sortable: false },
    ]

    get list() {
        return this.$store.state.application.reportList;
    }

    get loading() {
        return this.$store.state.application.loading;
    }

    async loadData() {
        await this.$store.dispatch({
            type: 'application/getAttentions',
            data: this.pagerequest
        });
    }

    mounted() {
        this.loadData();
    }
}
