






























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Util from '@/lib/util';
import VWidget from '@/components/VWidget.vue';
import ApexCharts from 'apexcharts'
import VueApexCharts from 'vue-apexcharts'


@Component({
    components: { apexcharts: VueApexCharts, VWidget },
})

export default class ApplicationsCharts extends ListBase {
    @Prop({ type: PageRequest, default: () => { return new PageRequest() } }) pagerequest: PageRequest;
    seriesPie: Array<any> = [1,0];
    options: any = {
        chart: {
            type: 'bar',
            height: 300
        },
        xaxis: {
            type: 'category',
        },
        title: {
            text: 'Totales',
        },
        tooltip: {
        },
    };
    optionsPie: any = {
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: ['Atendidos', 'Pendientes'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };
    
    get series() {
        return this.$store.state.dashboard.applicationBar;
    }

    //get seriesPie() {
    //    console.log(this.$store.state.dashboard.applicationPie)
    //    return this.$store.state.dashboard.applicationPie;
    //}
    
    get loading() {
        return this.$store.state.dashboard.loading;
    }

    get isCommercial() {
        return Util.abp.session.isCommercial();
    }

    async loadData() {
        await this.$store.dispatch({
            type: 'dashboard/getDashTickets',
            data: this.pagerequest
        });

        this.seriesPie = this.$store.state.dashboard.applicationPie;
    }

    mounted() { 
        this.loadData();
        this.optionsPie = {
            labels: ['Atendidos', 'Pendientes'],
        };
    }
}
